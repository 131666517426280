export default {
  welcome: {
    start: "Démarrer la visio",
    abstract: "Chat vidéo sécurisé",
    help: "En apprendre plus",
    created: "Créé par",
  },
  share: {
    title: "Inviter",
    link_info:
      "Partager cette salle avec les personnes avec lesquelles vous voulez discuter:",
    button_copy: "Envoyer",
    qr_info: "Vous pouvez aussi scanner ce QR Code avec un smartphone:",
    feedback:
      'En cas de soucis, envoyer un mail à support@a2display.fr',
    message:
      "Inviter en envoyant cette salle en appuyant sur le\n" +
      '          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round">\n' +
      '            <path d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8"></path>\n' +
      '            <polyline points="16 6 12 2 8 6"></polyline>\n' +
      '            <line x1="12" y1="2" x2="12" y2="15"></line>\n' +
      "          </svg>\n" +
      "          symbole.",
  },
  settings: {
    title: "Paramètres",
    video: "Source Vidéo",
    audio: "Source Audio",
    desktop: "Partager l'écran ou la fenêtre",
    blur: "Fond flou",
    blur_info:
      "Expérimental: un algorithme intelligent d'intelligence artificielle est capable d'indétifier les formes des personnes et de brouiller l'arrière-plan restant. Cela ajoute une certaine confidentialité visuelle à votre appel. Mais attention, c'est une fonctionnalité très consommatrice d'énergie et ne fonctionnera probablement pas sur les appareils mobiles!",
    bandwidth: "Optimiser la qualité",
    bandwidth_info:
      "Expérimental: Avec ce paramètre, A2Visio tente de réduire la bande passante en réduisant les données vidéo et audio.",
    fill: "Agrandir la vidéo",
    fill_info:
      "Le A2Visio tente d'utiliser autant que possible l'espace disponible sur l'écran en agrandissant la vidéo de manière à ce qu'elle s'intègre dans son cadre visuel. Lorsqu'elle est désactivée, vous verrez la vidéo entière à la place, mais avec des bordures autour d'elle.",
    subscribe: "S'abonner à cette salle",
    subscribe_info:
      "Expérimental: en vous abonnant, vous recevrez une notification lorsque quelqu'un d'autre entre dans cette salle. Vous pouvez ensuite rejoindre la conversation en un seul clic. Les notifications ne seront affichées que si le navigateur est en cours d'exécution.",
    sentry: "Autoriser le débuggage",
    sentry_info: `En cas d'erreur de programmation ou d'autres informations pertinentes utiles pour améliorer l'application, nous enverrons des données de débogage à un service appelé <a href="https://sentry.io"> sentry.io </a>.`,
    sentry_confirm:
      "Merci d'avoir autorisé le suivi des bogues. Veuillez confirmer pour recharger la page maintenant.",
  },
}
